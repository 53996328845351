<div class="page">

  <div class="container">

    <div class="navbar pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>

      <div class="d-flex">
        <div class=" ">
          <a mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>
        </div>
        <div>
          <app-actions></app-actions>
        </div>
      </div>
    </div>
    <div class="d-block text-center m-2">

      <h1>Translator for Figma</h1>
    </div>
    <div class="d-block text-center m-2">
      <div>Add seamless translation experience to your design tool</div>
    </div>
    <div class="row justify-content-center m-2">

      <a mat-button
         color="accent"
         class="install-button"
         href="https://www.figma.com/community/plugin/880826733808822304">Install the plugin</a>
    </div>

    <div class="row justify-content-center">

      <div class="row how-to-section">
        <div class="how-to-box">
          <iframe src="https://drive.google.com/file/d/1Pm00DoNj0MoKNbCvtmyTLamLSCwyCqGl/preview"
                  width="960" height="480"></iframe>

        </div>

      </div>
    </div>


  </div>



</div>
