<div class="page">

  <div class="container">

    <div class="navbar pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>

      <div class="d-flex">
        <div class=" ">
          <a mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>
        </div>
        <div>
          <app-actions></app-actions>
        </div>
      </div>
    </div>

    <div class="d-block text-center m-2">
      <h1>Limits</h1>
    </div>

    <mat-accordion>
      <mat-expansion-panel [hideToggle]="!isQuotasPresent">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Symbols limit:
          </mat-panel-title>
          <mat-panel-description>
            <ng-template [ngIf]="!limitsUnavailable">
              <ng-template [ngIf]="loading">Loading...</ng-template>
              <ng-template [ngIf]="!loading">{{data.Symbols|number}}</ng-template>
            </ng-template>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <table *ngIf="isQuotasPresent" mat-table [dataSource]="data.Details" class="w-100">

          <ng-container matColumnDef="Symbols">
            <th mat-header-cell *matHeaderCellDef> Symbols limit</th>
            <td mat-cell *matCellDef="let element"> {{element.Symbols|number}}</td>
          </ng-container>
          <ng-container matColumnDef="ExpDate">
            <th mat-header-cell *matHeaderCellDef> Expiration date</th>
            <td mat-cell *matCellDef="let element"> {{element.ExpDate|date}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="quotaColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: quotaColumns;"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel [hideToggle]="!isTeamAvailable">
        <mat-expansion-panel-header >
          <mat-panel-title>
            Subscription:
          </mat-panel-title>
          <mat-panel-description>
            <ng-template [ngIf]="!limitsUnavailable">{{ !loading ? subscription:"Loading..." }}</ng-template>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row  *ngIf="isTeamAvailable">
          <button mat-raised-button
                  class="m-2"
                  [disabled]="!isShareTeamEnabled"
                  (click)="shareTeam()">
            Share team
          </button>
          <button mat-raised-button class="m-2"
                  (click)="leaveTeam()"
                  [disabled]="!allowLeavingTeam">Leave team
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="limitsUnavailable">
      <div class="row justify-content-center warning-note">
        Limits unavailable.
      </div>
      <div class="row justify-content-center">
        <a mat-button routerLink="/login" routerLinkActive="active">
          Try to login.
        </a>
      </div>
    </div>

    <div *ngIf="paymentsDisabled"
         class="row justify-content-center warning-note">
      Payments temporally disabled
    </div>


    <div *ngIf="paymentAllowed">
      <br>
      
      <h2 class="d-block text-center">Subscriptions</h2>
      <div class="d-flex justify-content-center m-4">
        <mat-button-toggle-group name="amount"
                                 [(ngModel)]="paymentValue"
                                 aria-label="Payment amount">
          <mat-button-toggle value="s3"
                               class="m-2">
            <div>Basic subscription</div>
            <div class="sub-info">$3 for 3 months</div>
            </mat-button-toggle>

          <mat-button-toggle value="sUOne"
                               class="m-2">
            <div>Unlimited monthly</div>
            <div class="sub-info">$5 Monthly</div>
          </mat-button-toggle>

          <mat-button-toggle value="sUSix"
                               class="m-2">
            <div>Unlimited 6m</div>
            <div class="sub-info">$16 for 6 months</div>
          </mat-button-toggle>

          <mat-button-toggle value="s12"
                             class="m-2">
            <div>Team subscription</div>
            <div class="sub-info">$50 for 12 months</div>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <button mat-button color="accent"
                (click)="manageSubscription()"
                *ngIf="allowUnsubscribe">
          Manage subscription
        </button>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="paymentValue">

    <div *ngIf="showPersonalSubscriptionForTeams" class="row justify-content-center warning-note">
      Personal subscription will credit personal limits, even when you are a member of a team. You need to leave the
      team to use your Personal limits.
    </div>
    <div *ngIf="showTeamSubscriptionWarning" class="row justify-content-center warning-note">
      You already a team owner. Please contact us if you want to manage more than one team.
    </div>
    <div *ngIf="showTeamSubscriptionMemberWarning" class="row justify-content-center warning-note">
      You already joined a team. If you want to create a new team please leave current one.
    </div>
    <div *ngIf="showNewTeamSubscriptionWarning" class="row justify-content-center warning-note">
      With team subscription you can invite individuals to join your team. All team members use the team limits and share a dictionary.
      Team members are not required to make extra payments. Any team member can top up the team limits.
    </div>


    <div class="d-flex justify-content-center m-2">
      <button mat-raised-button
              class="pay-button"
              [disabled]="paymentSubmit"
              (click)="pay()">
        <span>Proceed to checkout</span>
      </button>
    </div>
  </div>


  <div *ngIf="paymentFailed"
       class="row justify-content-center warning-note">
    Payments has failed. Try again later.
  </div>
</div>
