import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {EditDialogComponent} from "../edit-dialog/edit-dialog.component";
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs";
import {StoreService} from "../store.service";
import {BillingData} from "../limits/limits.component";

export type TranslationStatus = 'auto' | 'manual' | 'review';


export interface Translation {
  src: string;
  status: TranslationStatus;
  translation: string;
}

export interface DictionaryPageResponse {
  data: string[][];
  last: boolean;
}

@Component({
  selector: 'app-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss']
})
export class DictionaryComponent implements OnInit {

  displayedColumns = ['src', 'translation', 'actions'];
  dictAvail: string[][] = [];
  sourceSelected = '';
  destSelected = '';
  allowedLoadMore = false;

  dataSource: MatTableDataSource<Translation>;

  data: Translation[] = [];
  filter = '';
  actualFilter = '';
  filterTimeout: any;
  dictSubscription: Subscription;
  dictionaryWarn = false;

  constructor(private dialog: MatDialog,
              private localStorage: StoreService,
              private http: HttpClient,) {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngOnInit(): void {
    this.http.get<string[][]>('/web/dicts/langs', {
      headers: {
        'X-XSRF-TOKEN': this.localStorage.getToken()
      }
    })
      .toPromise()
      .then((s) => {
        this.dictAvail = s;
        this.sourceSelected = this.sourceAvail[0];
        this.destSelected = this.destAvail[0];
        this.loadDict();
      });

    this.http.get<BillingData>('/web/limits', {
      headers: {
        'X-XSRF-TOKEN': this.localStorage.getToken()
      },
      observe: "response"
    }).subscribe((resp) => {
      const v = resp.body;
      this.dictionaryWarn = true;
      if (!!v.SubscriptionExp &&
        new Date(v.SubscriptionExp) > new Date()) {
        this.dictionaryWarn = false;
      }
    });
  }

  openDialog(key: string, translation: string) {
    const from = this.sourceSelected;
    const to = this.destSelected;
    const dialogRef = this.dialog.open(EditDialogComponent, {
      minWidth: 600,

      data: {
        key: key,
        translation: translation
      },
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if(result) {
          this.updateTranslation(key, result, from, to, translation);
        }
      });
  }

  /**
   * updates translation in table, sends save request to backend
   * if saving fails revert translation in table
   * @param key
   * @param val
   * @param from
   * @param to
   * @param recoveryValue
   */
  updateTranslation(key: string, val: string, from: string, to: string, recoveryValue: string) {
    if(this.destSelected === to && this.sourceSelected === from) {
      this.data
        .filter(t=> t.src === key)
        .forEach(t=>t.translation=val);
    }
    this.http.put('/web/dict', {
      from,
      to,
      key,
      val
    }).toPromise()
      .catch(()=>{
        if(this.destSelected === to && this.sourceSelected === from) {
          this.data
            .filter(t=> t.src === key)
            .forEach(t=>t.translation=recoveryValue);
        }
      });
  }

  get sourceAvail(): string[] {
    const strings = this.dictAvail.map(d => d[0]);
    return Array.from(new Set(strings));
  }

  get destAvail(): string[] {
    const strings = this.dictAvail
      .filter(d => d[0] === this.sourceSelected)
      .map(d => d[1]);
    return Array.from(new Set(strings));
  }

  cleanTable() {
    this.data = [];
    this.dataSource.data = [];
    this.actualFilter = '';
  }

  srcSelectionChange() {

    if (this.destAvail.length === 1) {
      this.destSelected = this.destAvail[0]

    } else if (this.destAvail.indexOf(this.destSelected) !== -1) {

    } else {
      this.destSelected = null;
    }

    this.cleanTable();
    this.filter = '';
    this.loadDict();
  }

  dstSelectionChange() {

    this.cleanTable();
    this.loadDict();
  }

  loadDict() {
    if (!this.sourceSelected || !this.destSelected) {
      return;
    }


    let start = ''
    if (this.data.length > 0) {
      start = this.data[this.data.length - 1].src;
    }
    this.allowedLoadMore = false;
    this.actualFilter = this.filter;
    if (this.dictSubscription) {
      this.dictSubscription.unsubscribe();
    }
    this.dictSubscription = this.http
      .post<DictionaryPageResponse>('/web/dict', {
      from: this.sourceSelected,
      to: this.destSelected,
      start,
      filter: this.filter

    }).subscribe(resp => {
        const newData: Translation[] = resp.data
          .map(r => {
            return {
              status: 'auto' as TranslationStatus,
              src: r[0],
              translation: r[1]
            }
          });
        this.data = this.data.concat(newData);
        this.dataSource.data = this.data;
        if (!resp.last) {
          this.allowedLoadMore = true;
        }
    });

  }

  filterChange(filter: string) {
    if (filter === this.actualFilter) {
      return;
    }
    if (!!this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.cleanTable();
    this.filter = filter;
    this.loadDict();
  }

  filterHit() {
    if (!!this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(()=> {
      if (this.filter === this.actualFilter) {
        return;
      }
      this.cleanTable();
      this.loadDict();
    }, 300);
  }

}
