import { Component, OnInit } from '@angular/core';
import {StoreService} from "../store.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  constructor(private localStorage: StoreService,
              private router: Router) { }

  ngOnInit(): void {
  }

  get mode(): 'anon'| 'auth' {
    return !!this.localStorage.getToken() ? 'auth' : 'anon';
  }

  logout() {
    this.router.navigate(['']).then(()=>{
      this.localStorage.logout();
    });
  }
}
