import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpResponse} from '@angular/common/http';
import {StripeService} from "../stripe.service";
import {map} from 'rxjs/operators';
import {StoreService} from "../store.service";
import {MatDialog} from "@angular/material/dialog";
import {JoinDialog} from "../join-dialog/join.dialog";
import {TeamDialogComponent, TeamDialogData} from "../team-dialog/team-dialog.component";

//
// Symbols       int
// SubscriptionExp *time.Time `json:",omitempty"`
// Details       []LimitInfo
// StripeUser    bool
// Team          bool `json:",omitempty"`
// TeamAdmin	  bool `json:",omitempty"`
// Sentences     int 							//deprecated
// DictionaryExp *time.Time `json:",omitempty"` // deprecated
// UpdatedId     string     `json:",omitempty"`
export interface BillingData {
  Symbols: number,
  SubscriptionExp?: Date,
  Details?: LimitInfo[],
  StripeUser?: boolean,
  Team?: boolean,
  TeamAdmin?: boolean,
}

export interface StripeInfo {
  Publickey?: string,
}

export interface InvitationResponse {
  Invitation: string
}

// type LimitInfo struct {
//   Symbols int
//   Labels  int
//   ExpDate time.Time
// }

export interface LimitInfo {
  Symbols: number,
  ExpDate: Date,
}

interface CheckoutData {
  'payment-session': string
}

interface PortalSession {
  url: string
}

@Component({
  selector: 'app-limits',
  templateUrl: './limits.component.html',
  styleUrls: ['./limits.component.scss'],

})
export class LimitsComponent implements OnInit {

  loading = false;
  data: BillingData;
  paymentValue: string;
  paymentSubmit = false;
  paymentFailed = false;
  limitsUnavailable = false;
  isShareTeamDisabled = false;

  quotaColumns = ['Symbols', 'ExpDate'];

  constructor(private stripeService: StripeService,
              private nav: Router,
              private http: HttpClient,
              private localStorage: StoreService,
              private dialog: MatDialog,
              private router: ActivatedRoute,
              private navRouter: Router,
              @Inject(LOCALE_ID) winRef: Window) {
  }

  async ngOnInit() {
    this.reloadLimits();
    this.router.queryParams
      .subscribe(params => {
        if (!!params['i']) {
          this.openJoinDialog(params['i']);
        }
      });


    this.http.get<StripeInfo>('/web/stripe', {
      headers: {
        'X-XSRF-TOKEN': this.localStorage.getToken()
      }
    })
      .subscribe((resp) => {
        this.stripeService.publicKey = resp.Publickey;
      });
  }

  reloadLimits(): void {
    this.loading = true;
    this.http.get<BillingData>('/web/limits', {
      headers: {
        'X-XSRF-TOKEN': this.localStorage.getToken()
      },
      observe: "response"
    })
      .subscribe({
        next: res => {
          this.data = res.body;
          this.loading = false;
        },
        error: (resp: HttpResponse<any>) => {
          this.limitsUnavailable = true;
              if (resp.status === 403) {
                this.localStorage.setItem("XSRF-TOKEN", "");
              }
        }
      });

  }

  get isActiveSubscription(): boolean {
    return  this.subscriptionExp > new Date();
  }

  get subscriptionExp(): Date {
    return !!this.data.SubscriptionExp && new Date(this.data.SubscriptionExp);
  }

  get subscription(): string {
    if (this.loading) {
      return '';
    }
    if (!this.isActiveSubscription) {
      return 'Not activated';
    } else if (!!this.data.Team) {
      return `Team access expires on ${this.subscriptionExp.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      })}`;
    } else {
      return `Dictionary access expires on ${this.subscriptionExp
        .toLocaleDateString(undefined, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        })}`;
    }
  }

  get allowUnsubscribe(): boolean {
    return !this.loading
      && !!this.data.StripeUser
      && this.stripeService.ready;
  }

  get paymentAllowed(): boolean {
    return !this.paymentsDisabled
      && !!this.data
      && this.stripeService.ready;
  }

  get paymentsDisabled() {
    return false;
  }

  pay() {
    if (this.paymentsDisabled) {
      return;
    }

    const stripeService = this.stripeService;
    this.paymentSubmit = true;

    this.http
      .post<CheckoutData>('/web/secret',
        {
          Product: this.paymentValue
        },
        {
          headers: {
            "Content-Type": "application/json"
          },
          observe: 'response'
        })
      .toPromise()
      .then((result) => {
        if (!result.ok) {
          throw {error: result};
        }
        return result.body;
      })
      .then((data) => {
        return stripeService.instance().pipe(
          map(stripe => {
            return stripe.redirectToCheckout({
              sessionId: data['payment-session']
            });
          })).toPromise();
      })
      .catch((obj) => {
          this.paymentFailed = true;
          setTimeout(() => this.paymentSubmit = false, 3000);
        }
      );
  }

  manageSubscription() {
    this.http.post<PortalSession>('/web/manage-subscription', {})
      .subscribe((v) => {
        window.open(v.url)
      });
  }


  get showPersonalSubscriptionForTeams(): boolean {
    return !this.loading
      && ['s3', 'sUOne', 'sUSix'].includes(this.paymentValue)
      && this.data.Team;
  }

  get showTeamSubscriptionWarning(): boolean {
    return !this.loading
      && this.paymentValue === 's12'
      && this.data.Team
      && this.data.TeamAdmin;
  }

  get showTeamSubscriptionMemberWarning(): boolean {
    return !this.loading
      && this.paymentValue === 's12'
      && this.data.Team
      && !this.data.TeamAdmin;
  }

  get showNewTeamSubscriptionWarning(): boolean {
    return !this.loading
      && this.paymentValue === 's12'
      && !this.data.Team;
  }

  get isQuotasPresent(): boolean {
    return !this.loading
      && !!this.data.Details
      && this.data.Details.length > 0;
  }

  get isTeamAvailable(): boolean {
    return !this.loading
      && this.data
      && this.data.Team;
  }

  get allowLeavingTeam(): boolean {
    return this.isTeamAvailable
      && !this.data.TeamAdmin;
  }

  get isShareTeamEnabled(): boolean {
    return this.isTeamAvailable
      && this.data.TeamAdmin;
  }


  async shareTeam() {
    this.isShareTeamDisabled = true;
    this.http.post<InvitationResponse>("/web/share-team", "",
      {
        headers: {
          'X-XSRF-TOKEN': this.localStorage.getToken()
        },
      })
      .subscribe(resp => {
        // this.shareUrl = `https://figmat.com/limits?i=${resp.Invitation}`;
          this.dialog.open(TeamDialogComponent, {
            minWidth: '500px',
            data: {
              code: resp.Invitation,
              url: `https://figmat.com/limits?i=${resp.Invitation}`
            } as TeamDialogData
          });
      },
        error => {
          this.isShareTeamDisabled = false
        });



  }

  private openJoinDialog(invitationId: string) {
    const matDialogRef = this.dialog.open(JoinDialog, {
      width: '400px',
      data: {invitationId: invitationId}
    });

    matDialogRef.afterClosed()
      .subscribe((v)=> {
        this.navRouter.navigate(['/limits'])
          .then(()=>{
            this.reloadLimits();
        });
      });
  }

  leaveTeam():void {
    this.http.post("/web/leave-team", "",
      {
        headers: {
          'X-XSRF-TOKEN': this.localStorage.getToken()
        },
      })
      .subscribe(resp => {
          this.reloadLimits();
        },
        error => {
          this.reloadLimits();
        });
  }

}




