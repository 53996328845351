<div class="page">
  <div class="container">
    <div class="navbar pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>

      <div class="d-flex">
        <div class=" ">
          <a mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>
        </div>
        <div>
          <app-actions></app-actions>
        </div>
      </div>
    </div>

    <div class="d-block text-center m-2">
      <h1>Dictionary</h1>
    </div>
    <div class="d-block text-center m-2">
      <div *ngIf="dictionaryWarn" class="no-access-warn">
        Dictionaries are available with subscriptions. Check <a routerLink="/pricing" routerLinkActive="active">pricing</a> for details.
      </div>
    </div>
    <div *ngIf="!dictionaryWarn"
         class="bg-white how-to-text filter-container">

      <mat-form-field class="p-1" appearance="fill">
        <mat-label>Source language</mat-label>
        <mat-select [(ngModel)]="sourceSelected"
                    (selectionChange)="srcSelectionChange()">
          <mat-option *ngFor="let item of sourceAvail"
            [value]="item">
            {{item}}
          </mat-option>
<!--          <mat-option value="valid">Valid option</mat-option>-->
<!--          <mat-option value="invalid">Invalid option</mat-option>-->
        </mat-select>
      </mat-form-field>

      <mat-form-field class="p-1" appearance="fill">
        <mat-label>Destination language</mat-label>
        <mat-select [(ngModel)]="destSelected"
                    (selectionChange)="dstSelectionChange()">
          <mat-option *ngFor="let item of destAvail"
                      [value]="item">
            {{item}}
          </mat-option>
          <!--          <mat-option value="valid">Valid option</mat-option>-->
          <!--          <mat-option value="invalid">Invalid option</mat-option>-->
        </mat-select>
      </mat-form-field>

      <mat-form-field class="p-1">
        <mat-label>Filter</mat-label>
        <input matInput placeholder="start entering" #input
               type="search"
               [(ngModel)]="filter"
               (keyup)="filterHit()"
               (change)="filterChange(input.value)">
      </mat-form-field>
    </div>



    <table mat-table
           *ngIf="!dictionaryWarn"
           [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="src">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
        <td mat-cell *matCellDef="let row"
          class="">
        <div class="data-cell">
          {{row.src}}
        </div>
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row">
            {{row.status}}
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button
                  (click)="openDialog(element.src, element.translation)">
            edit
          </button>

<!--          <button mat-button disabled-->
<!--                  (click)="openDialog('','')">-->
<!--            del-->
<!--          </button>-->
        </td>
      </ng-container>



      <!-- Name Column -->
      <ng-container matColumnDef="translation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Translation </th>
        <td mat-cell *matCellDef="let element"
            class="">
          <div class="data-cell">
            {{element.translation}}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">No data matching the filter "{{filter}}"</td>
      </tr>
    </table>

    <div *ngIf="!dictionaryWarn">
      <button mat-button [disabled]="!allowedLoadMore"
              class="load-more-button"
              (click)="loadDict()">
        Load more
      </button>
    </div>
  </div>


</div>
