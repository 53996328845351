<div class="pricing-page">

  <div class="container">

    <div class="navbar pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>

      <div class="d-flex">
        <div>
          <app-actions></app-actions>
        </div>
      </div>
    </div>
  </div>

  <div class="container payments-section ">
    <div class="w-100 text-center">
      <h1>Pricing</h1>
    </div>
    <!-- Display a payment form -->

    <div class="card-section row justify-content-center">

      <table mat-table
             [dataSource]="dataSource"
             class="">
        <ng-container matColumnDef="feature">
          <th mat-header-cell *matHeaderCellDef> Feature </th>
          <td mat-cell *matCellDef="let element"> {{element.feature}} </td>
        </ng-container>

        <ng-container matColumnDef="demo">
          <th mat-header-cell *matHeaderCellDef
              class="data-column"> demo </th>
          <td mat-cell *matCellDef="let element"
              class="data-column">
            <ng-container *ngIf="element.demo!=='y'">
            {{element.demo}}
            </ng-container>
            <ng-container *ngIf="element.demo==='y'">
              <img src="/assets/checkmark.png" alt="attention">
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="subThree">
          <th mat-header-cell *matHeaderCellDef
              class="data-column"> 3 months
            <div><a href="/limits">Select</a></div>
          </th>
          <td mat-cell *matCellDef="let element"
              class="data-column">
            <ng-container *ngIf="element.subThree!=='y'">
            {{element.subThree}}
            </ng-container>
            <ng-container *ngIf="element.subThree==='y'">
              <img src="/assets/checkmark.png" alt="attention">
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="uSubOne">
          <th mat-header-cell *matHeaderCellDef
              class="data-column"> Unlimited
            <div><a href="/limits">Select</a></div>
          </th>
          <td mat-cell *matCellDef="let element"
              class="data-column">
            <ng-container *ngIf="element.uSubOne!=='y'">
            {{element.uSubOne}}
            </ng-container>
            <ng-container *ngIf="element.uSubOne==='y'">
              <img src="/assets/checkmark.png" alt="attention">
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="uSubSix">
          <th mat-header-cell *matHeaderCellDef
              class="data-column"> 6m Unlimited
            <div><a href="/limits">Select</a></div>
          </th>
          <td mat-cell *matCellDef="let element"
              class="data-column">
            <ng-container *ngIf="element.uSubSix!=='y'">
            {{element.uSubSix}}
            </ng-container>
            <ng-container *ngIf="element.uSubSix==='y'">
              <img src="/assets/checkmark.png" alt="attention">
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="subYear">
          <th mat-header-cell *matHeaderCellDef
              class="data-column"> 12 months
            <div><a href="/limits">Select</a></div>
          </th>
          <td mat-cell *matCellDef="let element"
              class="data-column">
            <ng-container *ngIf="element.subYear!=='y'">
            {{element.subYear}}
            </ng-container>
            <ng-container *ngIf="element.subYear==='y'">
              <img src="/assets/checkmark.png" alt="attention">
            </ng-container>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>


    </div>
    <div>
      *) Figma plugin requires one-time payment if you use it with 3rd party translation services. Supported external provides: Google Translate, Deepl Translator, Yandex.Translate API.
    </div>

  </div>
</div>
