<h2>Translation edit</h2>

<div class="area-container">
  <textarea class="key-pad"
    matInput [(ngModel)]="key" readonly></textarea>

  <textarea class="translate-pad"dic
    matInput [(ngModel)]="translation"></textarea>
</div>

<div class="row">
  <button mat-button [mat-dialog-close]="translation">
    Save
  </button>
</div>
