import { Component, OnInit } from '@angular/core';
import {max} from "rxjs/operators";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {

  _page = '7';
  maxPage = 7;

  constructor() { }

  get page(): string {
    return this._page;
  }

  set page(n: string) {
    this._page = n;
    this.maxPage = Math.max(parseInt(n), this.maxPage);
  }


  allowRender(n: number): boolean {
    return n <= this.maxPage+1;
  }

  allowShow(n: number): boolean {
    return n===parseInt(this._page);
  }

}
