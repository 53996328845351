<button mat-button routerLink="/login" routerLinkActive="active" [hidden]="mode !== 'anon'">
  Login
</button>
<button mat-button [matMenuTriggerFor]="menu" [hidden]="mode !== 'auth'">Actions</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/limits" routerLinkActive="active">Limits</button>
  <button mat-menu-item routerLink="/dictionaries" routerLinkActive="active">Dictionaries</button>
<!--  <button mat-menu-item routerLink="/teams" routerLinkActive="active">Teams</button>-->
  <button mat-menu-item (click)="logout()">Log out</button>
</mat-menu>
