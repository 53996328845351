import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {WelcomeComponent} from './welcome/welcome.component';
import {PricingComponent} from './pricing/pricing.component';
import {LimitsComponent} from './limits/limits.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatButtonModule} from "@angular/material/button";
import {HowtoComponent} from './howto/howto.component';
import {HowtoJoinComponent} from './howto-join/howto-join.component';
import {HowtosComponent} from './howtos/howtos.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {LoginComponent} from './login/login.component';
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {AuthGuardService} from "./auth-guard.service";
import {CookieService} from 'ngx-cookie-service';
import {CommonModule} from "@angular/common";
import { DictionaryComponent } from './dictionary/dictionary.component';
import {MatDialogModule} from "@angular/material/dialog";
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import {MatSelectModule} from "@angular/material/select";
import { ActionsComponent } from './actions/actions.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatExpansionModule} from "@angular/material/expansion";
import { TeamsComponent } from './teams/teams.component';
import {JoinDialog} from "./join-dialog/join.dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import { TeamDialogComponent } from './team-dialog/team-dialog.component';
import {MatTabsModule} from "@angular/material/tabs";
import {ClipboardModule} from "@angular/cdk/clipboard";

const routes: Routes = [
  { path: '', component: WelcomeComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'limits',
    component: LimitsComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'dictionaries',
    component: DictionaryComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'howtojoin', component: HowtoJoinComponent  },
  { path: 'howtos', component: HowtosComponent  },
  { path: 'privacy', component: PrivacyComponent},
  { path: 'login', component: LoginComponent},
  // { path: 'teams', component: TeamsComponent},

  { path: '**',   redirectTo: '/' },
];


@NgModule({
    declarations: [
        AppComponent,
        WelcomeComponent,
        PricingComponent,
        LimitsComponent,
        HowtoComponent,
        HowtoJoinComponent,
        HowtosComponent,
        PrivacyComponent,
        LoginComponent,
        DictionaryComponent,
        EditDialogComponent,
        ActionsComponent,
        TeamsComponent,
        JoinDialog,
        TeamDialogComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        HttpClientModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatButtonToggleModule,
        MatGridListModule,
        MatButtonModule,
        MatInputModule,
        MatTableModule,
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatExpansionModule,
        MatDialogModule,
        MatFormFieldModule,
        MatTabsModule,
        ClipboardModule,
    ],
    providers: [
        CookieService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
