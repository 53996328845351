import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { Clipboard } from '@angular/cdk/clipboard';


export interface TeamDialogData {
  code: string;
  url: string;
}

@Component({
  selector: 'app-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.scss']
})
export class TeamDialogComponent implements OnInit {

  urlCheck: boolean = false;
  codeCheck: boolean = false;

  constructor(//public dialogRef: DialogRef<string>,
    private clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: TeamDialogData) {
  }

  ngOnInit(): void {
  }

  copyUrl() {

    if(this.clipboard.copy(this.data.url)) {
        this.urlCheck=true;
        setTimeout(()=>{
          this.urlCheck = false;
        }, 1000);
    }
  }

  copyCode() {
    if(this.clipboard.copy(this.data.code)) {
      this.codeCheck=true;
      setTimeout(()=>{
        this.codeCheck = false;
      }, 1000);
    }
  }





}
