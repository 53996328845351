import { Injectable } from '@angular/core';
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private cookieService: CookieService) { }

  getItem(key: string): string|null {
    return localStorage.getItem(key);
  }

  setItem(key: string, val: string) {
    localStorage.setItem(key, val);
  }

  getToken(): string| null{
    return this.getItem('XSRF-TOKEN');
  }

  logout() {
    localStorage.setItem('XSRF-TOKEN', '');
    this.cookieService.deleteAll();
  }
}
