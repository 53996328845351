<div mat-dialog-content>

  <div>
    <mat-label>Would you like to join new team</mat-label>
  </div>
  <div [hidden]="!success" class="alert alert-success">
    <mat-label class="">Success</mat-label>
  </div>
  <div [hidden]="!failed" class="alert alert-warning">
    <mat-label>Failed</mat-label>
  </div>
</div>

<div mat-dialog-actions [align]="'center'">
  <button mat-button mat-dialog-close [hidden]="!start">No Thanks</button>
  <button mat-button
          (click)="joinClick()"
          cdkFocusInitial [hidden]="!start" [disabled]="progress">Join
  </button>
  <button mat-button mat-dialog-close [hidden]="start">Close</button>
</div>
