import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {StoreService} from "../store.service";


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  inProgress = false;
  email = '';
  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);


  matcher = new MyErrorStateMatcher();

  constructor(private router: ActivatedRoute,
              private nav: Router,
              private cookieService: CookieService,
              private localStorage: StoreService,
              private http: HttpClient) {
  }

  ngOnInit(): void {
    // if 'code' - pass - reset auth
    // -> login animation
    //-> proceed auth
    //-> navigate to user intention or limits

    // else if auth present
    //  -> check validity (limits)
    //  -> move to limit/settings

    // show login interface

    this.router.queryParams.subscribe(params => {
      if (!params['code'] || !params['state']) {
        return;
      }
      this.inProgress = true;
      const code = params['code'];
      const source = params['state'];

      this.http.post<{}>('/web/auth',
        {
          code: code,
          source: source,
        },
        {
          withCredentials: true,
          observe: "response"
        })
        .subscribe((resp) => {
            // save xsrf
            // navigate to user-intention or limits
            this.localStorage.setItem("XSRF-TOKEN", this.cookieService.get('XSRF-TOKEN'));
            this.inProgress = false;
            return this.nav.navigate(['/limits']);
          },
          () => {
            this.inProgress = false;
            return this.nav.navigate(['/login']);
          });

    });
  }

  get hint() {
    return this.email.length > 0 ? 'Can not recognize the organization' : '';
  }

}
