import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {StoreService} from "../store.service";

export interface JoinDialogData {
  invitationId: string;
}

export enum JoinStatus {
  start,
  progress,
  success,
  failed,

}

@Component({
  selector: 'app-join-dialog',
  templateUrl: './join-dialog.component.html',
  styleUrls: ['./join-dialog.component.scss']
})
export class JoinDialog {
  status = JoinStatus.start;
  invitationsId: string;

  constructor(//public dialogRef: MatDialogRef<JoinDialog>,
              @Inject(MAT_DIALOG_DATA) public data: JoinDialogData,
              private localStorage: StoreService,
              private http: HttpClient) {
    this.invitationsId = data.invitationId;
  }


  joinClick(): void {

    this.status = JoinStatus.progress;
    this.http.post(`/web/join-team`,
      {
        InvitationId: this.invitationsId
      },
      {
        headers: {
          'X-XSRF-TOKEN': this.localStorage.getToken()
        },
      }
    )
      .subscribe(
        () => {
          this.status = JoinStatus.success;
        },
        () => {
          this.status = JoinStatus.failed;
        }
      )
  }

  get start(): boolean {
    return this.status === JoinStatus.start;
  }

  get progress(): boolean {
    return this.status === JoinStatus.progress;
  }

  get end(): boolean {
    return this.status === JoinStatus.failed || this.status === JoinStatus.success;
  }

  get success(): boolean {
    return this.status === JoinStatus.success;
  }

  get failed(): boolean {
    return this.status === JoinStatus.failed;
  }

}
