import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-howto-join',
  templateUrl: './howto-join.component.html',
  styleUrls: ['./howto-join.component.scss']
})
export class HowtoJoinComponent {

  _page = '1';
  maxPage = 1;

  constructor() { }

  get page(): string {
    return this._page;
  }

  set page(n: string) {
    this._page = n;
    this.maxPage = Math.max(parseInt(n), this.maxPage);
  }

  allowRender(n: number): boolean {
    return n <= this.maxPage+1;
  }

  allowShow(n: number): boolean {
    return n===parseInt(this._page);
  }
}
