import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  isNoTeams = true;
  teamName = 'contact@figmat.com';
  teamsColumns = ['teamName', 'renameTeam', 'Symbols', 'ExpDate', 'joinLeave', 'share'];
  teams=[];
  currentTeamMembers = [];
  teamMembersColumns = ['name']

  constructor() { }

  ngOnInit(): void {
  }

}
