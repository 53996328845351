import { Injectable } from '@angular/core';


/**
 * keep where the user was going before redirected to auth
 */
@Injectable({
  providedIn: 'root'
})
export class UserIntentionService {

  private path: string[] = [];
  constructor() { }

  push(route: string) {
    this.path.push(route)
  }

  pop(): string {
    return  this.path.pop();
  }

}
