<div class="page">

  <div class="container">
    <div class="row pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>
      <div class="ml-auto ">
        <a mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>

      </div>
      <div>
        <app-actions></app-actions>
      </div>
    </div>

    <div class="row justify-content-center m-2">
      <h1>Teams</h1>
    </div>

    <mat-accordion>
      <mat-expansion-panel [hideToggle]="!teamName">
        <mat-expansion-panel-header >
          <mat-panel-title>
            <ng-template [ngIf]="!teamName">Not a member of any team</ng-template>
            <ng-template [ngIf]="!!teamName">Connected to {{teamName}}</ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>Team members</div>
        <table mat-table [dataSource]="currentTeamMembers">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
              <button>Ban</button>>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="teamMembersColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: teamMembersColumns;"></tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>


    <h2 class="row justify-content-center">Available teams</h2>
    <table mat-table [dataSource]="teams" class="w-100">
      <ng-container matColumnDef="teamName">
        <th mat-header-cell *matHeaderCellDef> Team name</th>
        <td mat-cell *matCellDef="let element"> {{element.teamName}}</td>
      </ng-container>
      <ng-container matColumnDef="renameTeam">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <button>...</button>>
        </td>
      </ng-container>
      <ng-container matColumnDef="Symbols">
        <th mat-header-cell *matHeaderCellDef> Limit left</th>
        <td mat-cell *matCellDef="let element"> {{element.Symbols|number}}</td>
      </ng-container>
      <ng-container matColumnDef="ExpDate">
        <th mat-header-cell *matHeaderCellDef> Expiration date</th>
        <td mat-cell *matCellDef="let element"> {{element.ExpDate|date}}</td>
      </ng-container>
      <ng-container matColumnDef="joinLeave">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <button>Join/Leave</button>>
        </td>
      </ng-container>
      <ng-container matColumnDef="share">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <button>Share</button>>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="teamsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: teamsColumns;"></tr>
    </table>

  </div>

</div>
