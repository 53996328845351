<div class="page">

  <div class="container">

    <div class="row pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>
      <div class="ml-auto ">
        <a mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>
        <a mat-button routerLink="/howtos" routerLinkActive="active">How to</a>

      </div>
    </div>
  </div>
  <div class="row justify-content-center m-3">
    <div class="m-2">
      <h2>Settings and joining</h2>
      <a mat-button routerLink="/howtojoin" routerLinkActive="active">more...</a>
    </div>
    <div class="m-2">
      <iframe src="https://drive.google.com/file/d/1jD3HKZ1apWBkyolOiuQoAPVry08hbXSV/preview" width="640" height="480">
      </iframe>
    </div>
  </div>

</div>
