import {Inject, Injectable} from '@angular/core';
import {AsyncSubject, Observable} from "rxjs";
import {DOCUMENT} from "@angular/common";

export interface StripeInstance {
  elements: (settings: any) => void
  redirectToCheckout: (request: {
    sessionId: string
  }) => any
}

declare var Stripe: any;

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe = new AsyncSubject<StripeInstance>();
  private publicKeyV: string;
  private loaded = false;

  constructor(@Inject(DOCUMENT) private document) { }

  set publicKey(publicKey: string) {
    this.publicKeyV = publicKey;
  }

  instance(): Observable<StripeInstance> {
    if (!this.loaded) {
      this.loadScript();
    }

    return this.stripe;
  }

  get ready(): boolean {
    return !!this.publicKeyV;
  }

  loadScript() {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.stripe.com/v3/';
    script.onload = () => {
      this.stripe.next(Stripe(this.publicKeyV))
      this.stripe.complete();
    }
    this.document
      .getElementsByTagName('head')[0]
      .appendChild(script);
    this.loaded = true;
  }
}
