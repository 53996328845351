import { Component, OnInit } from '@angular/core';


export interface PriceFeature {
  feature: string;

  demo?: string;
  subThree?: string;
  uSubOne?: string;
  uSubSix?: string;
  subYear?: string;
}

const ELEMENT_DATA: PriceFeature[] = [
  {feature: "Price",
    demo: "free",
    subThree: '$3',
    uSubOne: '$5',
    uSubSix: '$16',
    subYear: '$50'
  },
  {
    feature: "Symbols limit",
    demo: '25K',
    subThree: '25K',
    uSubOne: 'Unlimited',
    uSubSix: 'Unlimited',
    subYear: '500K'

  },
  
  {
    feature: "Translation review from plugin",
    demo: 'y',
    subThree: 'y',
    uSubOne: 'y',
    uSubSix: 'y',
    subYear: 'y'
  },
  {
    feature: "Download dictionaries",
    demo: 'y',
    subThree: 'y',
    uSubOne: 'y',
    uSubSix: 'y',
    subYear: 'y'
  },
  {
    feature: "Access to personal dictionary",
    demo: '',
    subThree: 'y',
    uSubOne: 'y',
    uSubSix: 'y',
    subYear: 'y'
  },
  {
    feature: "Team management",
    demo: '',
    subThree: '',
    uSubOne: '',
    uSubSix: '',
    subYear: 'y'
  },
  {
    feature: "Team's dictionary",
    demo: '',
    subThree: '',
    uSubOne: '',
    uSubSix: '',
    subYear: 'y'
  },
  {
    feature: "Access to API for CI integration",
    demo: '',
    subThree: '',
    uSubOne: '',
    uSubSix: '',
    subYear: 'y'
  },
];

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  dataSource = ELEMENT_DATA;
  displayedColumns = ['feature', 'demo', 'subThree', 'uSubOne', 'uSubSix', 'subYear'];

  constructor() { }

  ngOnInit(): void {

  }

}
