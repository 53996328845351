import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  key: string;
  translation: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.key = data.key;
    this.translation = data.translation;
  }

  ngOnInit(): void {
  }

}
