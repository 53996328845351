<div class="page">
  <div class="container">

    <div class="row pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>
      <div class="ml-auto ">
        <a mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>
        <a mat-button routerLink="/howtos" routerLinkActive="active">How to</a>

      </div>
    </div>

    <div class="row justify-content-center">

      <h1>Settings and joining</h1>
    </div>



    <div class="row justify-content-center">

      <div class="row how-to-section">
        <div class="how-to-box">
          <iframe *ngIf="allowRender(1)"
                  [hidden]="!allowShow(1)"
                  src="https://drive.google.com/file/d/1sTm3rriIHOOKc2iaBDAjyYa2ye73T8-h/preview" width="640"
                  height="480"></iframe>
          <iframe *ngIf="allowRender(2)"
                  [hidden]="!allowShow(2)"
                  src="https://drive.google.com/file/d/1b3bDRyqig1KBS0mppBklnBwzRznccWhh/preview" width="640"
                  height="480"></iframe>
          <iframe *ngIf="allowRender(3)"
                  [hidden]="!allowShow(3)"
                  src="https://drive.google.com/file/d/1Q3O4mHaLpYSqu5wDLfMDDTo73VeAz0nd/preview" width="640"
                  height="480"></iframe>
          <iframe *ngIf="allowRender(4)"
                  [hidden]="!allowShow(4)"
                  src="https://drive.google.com/file/d/1ZC9FNHn5MhYqAqBOo5QCFh4rMf3ryKQi/preview" width="640"
                  height="480"></iframe>
          <iframe *ngIf="allowRender(5)"
                  [hidden]="!allowShow(5)"
                  src="https://drive.google.com/file/d/1upyycxmUHSfnGN2UxbQgYKcdF-2f6nOW/preview" width="640"
                  height="480"></iframe>
          <iframe *ngIf="allowRender(6)"
                  [hidden]="!allowShow(6)"
                  src="https://drive.google.com/file/d/1bftN7b6EAKy-8GE-Ua6CIB009NZNSawt/preview" width="640"
                  height="480"></iframe>
        </div>
      </div>
    </div>
    <div class="row justify-content-center m-4">

      <mat-button-toggle-group #selector name="number"
                               [(ngModel)]="page"
                               aria-label="image number">
        <mat-button-toggle value="1">1</mat-button-toggle>
        <mat-button-toggle value="2">2</mat-button-toggle>
        <mat-button-toggle value="3">3<img src="/assets/warning.png" alt="attention"></mat-button-toggle>
        <mat-button-toggle value="4">4</mat-button-toggle>
        <mat-button-toggle value="5">5</mat-button-toggle>
        <mat-button-toggle value="6">6</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="row">
      <div [hidden]="!allowShow(3)">It is crucial to set up both the Default language and Translation scope right.
        <br>The default language is the language you maintain the project. It is used as a source language
        to translate into all other languages. If you revert a page to the default language Figmat recovers the text.
        The original text you enter on a TextLabel creation is recognized as written in the default language even
        if the page already translated.
        <br>Scope for translation allows you to limit text for translation. If you choose the "All" option, then all
        TextNodes will be processed. If the option "Exported" scope is selected, then only TextNodes that have
        an exported method added will be translated.
        These options are global for your machine. It could be critical if you have projects in different default languages.
      </div>
    </div>
  </div>
</div>
