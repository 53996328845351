
<router-outlet></router-outlet>

<footer class="mt-auto">
  <div class="container">
    <div class="d-flex justify-content-end">
      <a mat-button routerLink="/privacy" routerLinkActive="active">Privacy</a>
      <a mat-button href="mailto:contact@figmat.com">
        Contact us
      </a>
    </div>
  </div>
</footer>
